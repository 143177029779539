@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Arsenal Regular';
  src: url('/assets/fonts/Arsenal-Regular.woff2') format('woff2'),
  url('/assets/fonts/Arsenal-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arsenal Bold';
  src: url('/assets/fonts/Arsenal-Bold.woff2') format('woff2'),
  url('/assets/fonts/Arsenal-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald Regular';
  src: url('/assets/fonts/Oswald-Regular.woff2') format('woff2'),
  url('/assets/fonts/Oswald-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald Medium';
  src: url('/assets/fonts/Oswald-Medium.woff2') format('woff2'),
  url('/assets/fonts/Oswald-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald Bold';
  src: url('/assets/fonts/Oswald-Bold.woff2') format('woff2'),
  url('/assets/fonts/Oswald-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  background-color: #F3F4F6 !important;
  min-height: 100%;
  font-family: "Arsenal Regular", serif !important;
}

p, h1, h2, h3, h4, h5, h6, span, a, li, button {
  font-family: inherit;
}

a {
  text-decoration: none !important;
}

.arsenal {
  font-family: "Arsenal Regular", serif !important;
}

.arsenal-bold {
  font-family: "Arsenal Bold", serif !important;
}

.oswald {
  font-family: "Oswald Regular", serif !important;
}

.oswald-medium {
  font-family: "Oswald Medium", serif !important;
}

.oswald-bold {
  font-family: "Oswald Bold", serif !important;
}

.text-requisit {
  @apply text-size-3 normal-line-height;
}

.text-dark-color {
  color: #0B0322;
}

.bg-dark-color {
  background-color: #0B0322;
}

.text-blue-color {
  color: #13077A;
}

.bg-blue-color {
  background-color: #13077A;
}

.text-yellow-color {
  color: #E3BD15;
}

.bg-yellow-color {
  background-color: #E3BD15;
}

.text-cool-gray-color {
  color: #8C8DA4;
}

.bg-cool-gray-color {
  background-color: #8C8DA4;
}

.border-blue-color {
  border-color: #13077A;
}

.ease-color-transition {
  transition: color 0.2s ease, background-color 0.2s ease;
}

.text-yellow-on-hover:hover {
  color: #E3BD15;
}

.logo-horizontal {
  width: 380px;
}

.title-bold {
  @apply oswald-medium text-size-8 text-dark-color pt-12 pb-10;
}

.title-bold-yellow {
  @apply oswald-medium text-size-8 text-yellow-color pt-12 pb-10;
}

.title-bold-no-left-paddings {
  @apply oswald-medium text-size-8 text-dark-color pt-12 pb-10 pr-3;
}

.top-page-start-margin {
  @apply mt-12;
}

.title-bold-yellow-no-left-paddings {
  @apply oswald-medium text-size-8 text-yellow-color pt-12 pb-10 pr-3;
}

.no-margins {
  @apply m-0;
}

.description-text-limit-width {
  width: 90%;
  max-width: 1000px;
}

.button-blue-hover-yellow {
  @apply py-2 px-4 rounded bg-blue-color text-white text-size-3;
  line-height: 1.15 !important;
  transition: all 0.2s ease;
}
.button-blue-hover-yellow:hover {
  @apply bg-yellow-color text-dark-color;
}

@media (max-width: 1650px) {
  .logo-horizontal {
    width: 340px;
  }
}

.grow-on-hover-110 {
  transition: transform 0.2s ease;
}
.grow-on-hover-105 {
  transition: transform 0.2s ease;
}

.grow-on-hover-110:hover {
  transform: scale(1.1);
}
.grow-on-hover-105:hover {
  transform: scale(1.05);
}

.footer-title-font {
  @apply text-size-3 text-cool-gray-color;
}

.footer-item-font {
  @apply text-size-1 text-yellow-on-hover text-white ease-color-transition normal-line-height;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-4-3 {
  aspect-ratio: 4 / 3;
}

.aspect-5-3 {
  aspect-ratio: 5 / 3;
}

.block-bottom-padding {
  padding-bottom: 60px;
}

.less-line-height {
  line-height: 1.1 !important;
  display: inline-block;
}

.submenu-font {
  @apply text-size-3;
}

.menu-font {
  @apply text-size-5;
}

.bottom-page-padding {
  padding-bottom: 80px;
}

.normal-line-height {
  line-height: 1.2 !important;
  display: inline-block;
}

.line-height-1-5 {
  line-height: 1.5 !important;
}

.line-height-1-4 {
  line-height: 1.4 !important;
}

.line-height-1-3 {
  line-height: 1.3 !important;
}

.line-height-1-2 {
  line-height: 1.2 !important;
}

.line-height-1-1 {
  line-height: 1.1 !important;
}

.line-height-1-0 {
  line-height: 1.0 !important;
}

.line-height-0-9 {
  line-height: 0.9 !important;
}

.line-height-0-8 {
  line-height: 0.8 !important;
}

.line-height-0-7 {
  line-height: 0.7 !important;
}

.left-side {
  position: relative;
  margin-left: 10%;
}

.search-input-field {
  @apply ml-2 mr-2 border border-gray-300 rounded-sm py-2 px-4 input-text-size;
  width: 500px;
}

.search-input-div {
  @apply mx-auto;
}

.large-window {
  @apply w-[1150px] relative rounded mt-3 p-6 bg-white left-1/2 -translate-x-1/2;
}

@media (max-width: 1250px) {
  .large-window {
    @apply w-11/12 relative rounded mt-3 p-6 bg-white left-1/2 -translate-x-1/2;
  }

  .logo-horizontal {
    width: 300px;
  }

  .left-side {
    margin-left: 8%;
  }
}

.modal-background {
  @apply scroll-auto bg-black/30 backdrop-blur-sm fixed top-0 right-0 left-0 bottom-0;
}

.modal-window-kate {
  @apply w-[600px] top-0 absolute rounded pt-4 pb-4 pl-5 pr-5 bg-white left-1/2 -translate-x-1/2;
}

.modal-title {
  font-size: 22px;
  line-height: 1.2 !important;
  display: inline-block;
}

.custom-form-control {
  @apply appearance-none input-text-size border border-gray-300 rounded-md;
  box-shadow: 0 0 2px 1px rgba(130, 130, 130, 0.08);
  min-width: 100px;
}

.text-size-0 {
  font-size: 14px;
}

.text-size-1 {
  font-size: 16px;
}

.text-size-2 {
  font-size: 18px;
}

.text-size-3 {
  font-size: 20px;
}

.text-size-4 {
  font-size: 23px;
}

.text-size-5 {
  font-size: 26px;
}

.text-size-6 {
  font-size: 30px;
}

.text-size-7 {
  font-size: 34px;
}

.text-size-8 {
  font-size: 40px;
}

.text-size-9 {
  font-size: 46px;
}

.text-size-10 {
  font-size: 52px;
}

.text-size-11 {
  font-size: 60px;
}

.normal-paddings {
  @apply py-2 px-3;
}

.less-paddings {
  @apply py-2 px-1.5;
}

.input-text-size {
  font-size: 16px;
}

@media (max-width: 700px) {
  .left-side {
    margin-left: 35px;
  }

  .block-bottom-padding {
    padding-bottom: 45px;
  }

  .text-size-0 {
    font-size: 12px;
  }

  .text-size-1 {
    font-size: 14px;
  }

  .text-size-2 {
    font-size: 16px;
  }

  .text-size-3 {
    font-size: 18px;
  }

  .text-size-4 {
    font-size: 21px;
  }

  .text-size-5 {
    font-size: 24px;
  }

  .text-size-6 {
    font-size: 28px;
  }

  .text-size-7 {
    font-size: 32px;
  }

  .text-size-8 {
    font-size: 35px;
  }

  .title-bold {
    @apply pt-10 pb-7;
  }

  .title-bold-no-left-paddings {
    @apply pt-10 pb-7;
  }

  .top-page-start-margin {
    @apply mt-10;
  }

  .title-bold-yellow-no-left-paddings {
    @apply pt-10 pb-7;
  }

  .bottom-page-padding {
     padding-bottom: 70px;
   }
}

@media (max-width: 635px) {
  .modal-window-kate {
    @apply w-11/12;
  }

  .search-input-field {
    width: 95%;
  }

  .search-input-div {
    width: 80%;
    @apply ml-12;
  }

  .left-side {
    margin-left: 30px;
  }
}

@media (max-width: 1100px) {
  .text-size-0 {
    font-size: 13px;
  }

  .text-size-1 {
    font-size: 15px;
  }

  .text-size-2 {
    font-size: 17px;
  }

  .text-size-3 {
    font-size: 19px;
  }

  .text-size-4 {
    font-size: 22px;
  }

  .text-size-5 {
    font-size: 25px;
  }

  .text-size-6 {
    font-size: 29px;
  }

  .text-size-7 {
    font-size: 33px;
  }

  .text-size-8 {
    font-size: 38px;
  }

  .title-bold {
    @apply text-size-7 pt-12 pb-8 px-4;
  }

  .title-bold-no-left-paddings {
    @apply text-size-7 pt-12 pb-8;
  }

  .top-page-start-margin {
    @apply mt-12;
  }

  .title-bold-yellow-no-left-paddings {
    @apply text-size-7 pt-12 pb-8;
  }
}

@media (max-width: 519px) {
  .logo-horizontal {
    @apply w-5/6;
  }

  .submenu-font {
    @apply text-size-2;
  }
}

@media (max-width: 440px) {
  .modal-title {
    font-size: 17px;
  }

  .block-bottom-padding {
    padding-bottom: 40px;
  }

  .left-side {
    margin-left: 20px;
  }

  .search-input-field {
    @apply py-1;
  }

  .text-size-0 {
    font-size: 11px;
  }

  .text-size-1 {
    font-size: 13px;
  }

  .text-size-2 {
    font-size: 15px;
  }

  .text-size-3 {
    font-size: 17px;
  }

  .text-size-4 {
    font-size: 20px;
  }

  .text-size-5 {
    font-size: 23px;
  }

  .text-size-6 {
    font-size: 27px;
  }

  .text-size-7 {
    font-size: 31px;
  }

  .normal-paddings {
    @apply py-1 px-2;
  }

  .less-paddings {
    @apply py-0 px-2;
  }

  .modal-window-kate {
    @apply pl-4 pr-4;
  }

  .input-text-size {
    font-size: 14px;
  }

  .text-size-8 {
    font-size: 33px;
  }

  .title-bold {
    @apply text-size-6 pt-9 pb-6;
  }

  .title-bold-no-left-paddings {
    @apply text-size-6 pt-9 pb-6;
  }

  .top-page-start-margin {
    @apply mt-9;
  }

  .title-bold-yellow-no-left-paddings {
    @apply text-size-6 pt-9 pb-6;
  }

  .bottom-page-padding {
    padding-bottom: 60px;
  }
}

h1 {
  @apply break-words;
  overflow-wrap: anywhere;
}

h2 {
  @apply break-words;
  overflow-wrap: anywhere;
}

span {
  @apply break-words;
  overflow-wrap: anywhere;
}

p {
  @apply break-words;
  overflow-wrap: anywhere;
}
